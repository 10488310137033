@import '../../styles/variables.scss';

.container {
  border: 1px solid $otcGray;

  &::before {
    content: '';
    position: relative;
    display: block;
    height: 7px;
    background-color: $otcGreen;
  }

  &.error {
    &::before {
      background-color: $otcErrorColor;
    }
  }

  .content {
    padding: $mediumSpace;
    word-wrap: break-word;

    .title {
      margin-bottom: 0;
      flex: 1;
    }

    .secondary {
      margin-left: $mediumSpace;
    }

    .complete {
      color: $otcGreen;

      .icon {
        margin-right: $smallSpace
      }
    }


    .titleSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}