@import "../../styles/variables.scss";

.container {
  position: relative;
  width: 190px;

  .playIcon {
    position: absolute;
    color: $otcGreen;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.green {
      color: $otcGreen;
    }

    &.pink {
      color: $otcPink;
    }

    &.orange {
      color: $otcOrange;
    }

    &.gray {
      border-color: $otcGray;
    }
  }

  .thumbnail {
    width: 100%;
    opacity: 0.6;
  }
}
