@import "../../styles/variables.scss";

.container {
    font-family: $otcFontPrimary, sans-serif;
    line-height: 1.5em;
    
    h1 {
        @include h1;
        margin-top: 0;
        line-height: 1em;
    }
    h2 {
        @include h2;
    }
    h3 {
        @include h3;
    }

    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: $otcDarkGray;

    .compHeader {
        max-width: $max-width;
    }

    main {
        flex: 1;
        max-width: $max-width;
        width: 100%;
        margin: $XLargeSpace auto $XXLargeSpace;
        padding: 0 $mediumSpace;
    }
}