@import "../../styles/variables.scss";

.container {
  .gridCol2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $XLargeSpace;
  }

  .gridCol3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .controls {
    display: flex;
    margin-top: 50px;
    justify-content: flex-end;
    align-items: center;
  }

  .content {
    display: flex;

  }

  .longTitle{
    grid-column:  span 3;
  }
}

.pdfPageContainer{
  max-height: 800px; // needed for scroll
  overflow-y: scroll; // yes vertical scroll
  overflow-x: hidden; // no horizontal scroll
  border: 2px solid lightgray; // matches the scroll color
  border-radius: 5px;
}

.noHorizontalScroll{
  overflow-x: hidden !important; // no horizontal scroll
}

.loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid $otcGray;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 