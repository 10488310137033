@import "../../styles/variables.scss";

.container {
  .gridCol3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $XLargeSpace;
  }

  .controls {
    display: flex;
    margin-top: 50px;
    justify-content: flex-end;
    align-items: center;
  }

  .loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid $otcGray;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
}
