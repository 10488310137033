@import "../../styles/variables.scss";

.container {
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    color: $otcGreen;
  }

  &.green:hover {
  color: $otcGreen;
  }

  &.pink:hover {
  color: $otcPink;
  }

  &.orange:hover {
  color: $otcOrange;
  }

  &.gray:hover {
  border-color: $otcGray;
  }

  .text {
    padding-left: 4px;
    text-decoration: underline;
  }
}

.controls {
  text-align: right;
  margin-top: $XLargeSpace;
}
