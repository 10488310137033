@import "../../styles/variables.scss";

.dropzoneActive {
    opacity: .5;
}

.dropContainer {
    display: block;
    text-align: center;
    width: 100%;
    border: 1px dashed $otcBlack;
    padding: $XLargeSpace 0;
    cursor: pointer;

    &.invalid{
        border-color: $otcErrorColor;
        border-width: 2px;
    }

    .chooseFile {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $mediumSpace;

        .text {
            font-size: 20px;
            margin-left: $smallSpace;
            font-weight: $fontWeightSemiBold;
        }
    }

    .instructions {
        font-size: 12px;
    }
}

.selectedFile {
    display: flex;
    align-items: center;
    font-weight: $fontWeightSemiBold;
    border: 1px solid $otcBlack;
    padding: $mediumSpace;

    &.disabled {
        opacity: .5;

        .removeIcon {
            cursor: inherit;
        }
    }
    .removeIcon {
        cursor: pointer;
        margin-right: $smallSpace;
    }
}

.fileError {
    font-style: italic;
    color: $otcErrorColor;
    margin-top: $smallSpace;
}