@import "../../styles/variables.scss";

$infoTextFontSize: 0.9em;

.container {
  display: grid;
  grid-template-columns: 7fr 5fr auto;
  font-family: $otcFontPrimary;

  @include sm-md {
    display: block;
  }

  .main {
    display: flex;
    flex: 1 1 100%;

    @include sm {
      display: block;
      padding-bottom: 0.5em;
    }
  }

  .companyHeader {
    display: grid;
    grid-template-columns: auto 1fr;

    .companyLogoContainer {
      display: flex;
      align-items: center;

      .companyLogo {
        max-width: 140px;
        max-height: 75px;
        margin-top: -40px;
        margin-right: 20px;

        @include sm {
          margin: 0 0 $mediumSpace;
        }
      }
    }

    @include sm {
      display: block;
      width: 100%;
    }
  }

  .bid {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include sm-md {
      justify-content: start;
    }

    @include sm {
      margin-top: 1em;
    }
  }

  .price {
    font-size: 2em;
    line-height: 22px;
  }

  .companyBid {
    padding-right: $largeSpace;
    border-right: 1px solid var(--otc-light-gray);

    @include sm-md {
      text-align: left;
    }

    @include sm {
      border-right: none;
      border-top: 1px solid var(--otc-light-gray);
      margin-top: $mediumSpace;
      padding: 0;
    }

    @include md {
      display: inline-flex;
      margin-top: $largeSpace;
    }
  }

  .companyName {
    font-size: 1.75em;
    font-weight: $fontWeightSemiBold;
    line-height: 28px;

    &:empty::after {
      content: " ";
      white-space: pre;
    }
  }

  .ticker {
    font-size: 1.25em;
  }

  h2 {
    @include h2;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .subHeader {
    font-size: 0.75em;
    color: var(--otc-medium-gray);
    display: inline-block;
    font-weight: 300;
    position: relative;
    line-height: 1.2em;

    &:empty::after {
      content: " ";
      white-space: pre;
    }

    * {
      line-height: 1.2em;
    }
  }

  .evo {
    @include h2;
    margin-left: 0.2em;
    text-align: left;

    p {
      font-size: 0.36em;
      line-height: 1.25em;
      margin: 0;
    }
  }

  .iconArrow {
    font-size: 1.25em;
    margin-right: -0.2em;
    width: 1.25em;
  }

  .positive {
    svg,
    p {
      color: $otcGreen;
    }

    svg {
      margin-top: -0.02em;
    }
  }

  .negative {
    svg,
    p {
      color: $otcErrorColor;
    }
  }

  .info {
    flex: 0 0 300px;
    flex-direction: column;
    max-width: 300px;
    padding-left: $largeSpace;

    @include sm-md {
      margin-top: $mediumSpace;
      max-width: none;
    }

    @include sm {
      border-top: 1px solid var(--otc-light-gray);
      padding-top: $mediumSpace;
      padding-left: 0;
    }

    @include md {
      display: inline-flex;
      margin-right: 0;
    }
  }

  .profile {
    .tierText {
      font-size: $infoTextFontSize;
      color: var(--otc-medium-gray);
      padding-bottom: 0.5em;

      @include sm {
        border-top: 1.5px solid var(--otc-light-gray);
        padding-top: 0.5em;
        padding-bottom: 0em;
      }
    }

    .informationOutline {
      padding-top: 0;
      border-top: none;
      font-size: 1em !important;
    }

    .infoText {
      font-size: $infoTextFontSize;
      color: var(--otc-medium-gray);
      display: block;
      width: 100%;
      text-decoration: none;
      line-height: 1.6em;

      @include sm {
        margin-bottom: 0.5em;
      }
    }

    img {
      max-width: 7.5rem;
      margin-bottom: 0.2em;

      &.award {
        height: 4.1rem;
      }
    }
  }
}

.noChange {
  svg,
  p {
    color: var(--otc-medium-gray);
    margin-left: 0.2em;
  }
}

@include sm {
  .ask {
    padding: 0.3em 0 0.2em 0 !important;
  }
}

@include md-lg {
  .ask {
    font-size: 0.9em;
    line-height: 1.8em;
    position: relative;
  }

  .companyBid {
    text-align: right;
  }
}
