@import '../../styles/variables.scss';

.container {
  display: inline-block;
  &.small {
    font-size: 12px;
    font-weight: $fontWeightBold;
  }
  
  &.big {
    @include h2;
    margin: 0 0 $smallSpace;
  }
}