@import "../../styles/variables.scss";

.container {
    background-color: var(--otc-light-gray);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: $otcDarkGray;
    padding: $smallSpace 0;
 
    main {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }
}