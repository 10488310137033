@import "../../styles/variables.scss";

.container {
  position: relative;
  width: 100%;

  .link {
    cursor: pointer;
    color: inherit;
    text-decoration: underline;

    div {
      display: inline-block;
      text-decoration: underline;
      padding-left: 4px;
    }

    &:hover {
      color: $otcGreen;
    }

    &.green:hover {
      color: $otcGreen;
    }

    &.pink:hover {
      color: $otcPink;
    }

    &.orange:hover {
      color: $otcOrange;
    }

    &.gray:hover {
      border-color: $otcGray;
    }
  }
}

.colWrap {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.dialogControls {
  text-align: right;
}

.confrmMsg {
  color: $otcGreen;
  font-size: 20px;
  font-weight: $fontWeightBold;
  margin-bottom: $mediumSpace;
}

.statusDENIED {
  color: $otcErrorColor;
}
