@import "../../styles/variables.scss";

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: $max-width;
    width: 100%;
    margin: $XLargeSpace auto $XXLargeSpace;
    padding: 0 $mediumSpace;
  }

  .title {
    text-align: center;
    margin-top: 0.1em;
    font-size: 18em;
    font-weight: bold;
    line-height: 1em !important;
    span {
      line-height: 1em !important;
    }
    span:nth-child(1) {
      color: $otcGreen;
    }
    span:nth-child(2) {
      color: $otcOrange;
    }
    span:nth-child(3) {
      color: $otcPink;
    }

    @include md {
      font-size: 15em;
    }
    @include sm {
      font-size: 9em;
    }
  }
  .heading {
    text-align: center;
    font-size: 1.75em;
    line-height: 1.8em;
    font-weight: bold;
  }